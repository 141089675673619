import { post } from '@/utils/request'

//添加店铺
export function getIncome(data) {
    return post('/room/dashboard/income', data)
}

// 店铺列表
export function currentCheckInRecord(data) {
    return post('/room/dashboard/currentCheckInRecord', data)
}