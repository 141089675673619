<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>报表管理</h2>
        <div class="box-bd">
          <div class="tab">
            <el-tabs v-model="activeName">
              <el-tab-pane label="收益" name="first">
                <el-date-picker
                  v-model="date_income"
                  type="date"
                  placeholder="单日收入查询"
                  value-format="yyyy-MM-dd"
                  @change="changeDate"
                >
                </el-date-picker>
                <span style="margin-left: 20px;color:red">{{ inc }}</span>
                <el-table :data="incomeData">
                  <el-table-column prop="date" label="日期"> </el-table-column>
                  <el-table-column label="收益">
                    <template v-slot="scope">
                      {{ scope.row.income.toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="入住记录" name="second">
                <el-row type="flex" :gutter="20">
                  <el-col :span="2"
                    ><el-tag type="info" effect="dark">条件查询</el-tag></el-col
                  >
                  <el-col style="display: flex;" :span="22">
                    <el-input
                      size="medium"
                      placeholder="入住人"
                      v-model="query.customer"
                    >
                    </el-input>
                    <el-input
                      size="medium"
                      style="margin: 0 20px;"
                      placeholder="请输入订单编号"
                      v-model="query.order_no"
                    >
                    </el-input>
                    <el-input
                      size="medium"
                      placeholder="请输入联系电话"
                      v-model="query.phone"
                    >
                    </el-input>
                    <el-button
                      type="success"
                      icon="el-icon-search"
                      size="mini"
                      @click="getCheckinRecord"
                      >查询</el-button
                    >
                  </el-col>
                </el-row>
                <el-table :data="checkinData">
                  <el-table-column prop="customer" label="入住人">
                  </el-table-column>
                  <el-table-column prop="id_number" label="证件号">
                  </el-table-column>
                  <el-table-column prop="order_no" label="订单号">
                  </el-table-column>
                  <el-table-column prop="start_time" label="登记日期">
                  </el-table-column>
                  <el-table-column prop="price" label="价格"></el-table-column>
                  <el-table-column prop="room_name" label="房间">
                  </el-table-column>
                  <el-table-column prop="spec_name" label="规格">
                  </el-table-column>
                </el-table>
                <el-pagination
                  :pager-count="15"
                  @current-change="pageChange"
                  @size-change="sizeChange"
                  :page-sizes="[7, 15, 20]"
                  layout="total, sizes, prev, pager, next"
                  :page-size="this.query.limit"
                  background
                  :total="exp.total"
                >
                </el-pagination>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getIncome, currentCheckInRecord } from '@/api/room/sheet.js'
export default {
  data() {
    return {
      exp: { total: 0 },
      activeName: 'first',
      incomeData: [],
      checkinData: [],
      date_income: '',
      inc: '',
      query: {
        page: 1,
        limit: 15,
        page_code: '',
        customer: '',
        phone: '',
        create_time: '',
      },
    }
  },
  created() {
    this.getIncomeList()
    this.getCheckinRecord()
  },
  watch: {
    date_income(newVal, old) {
      let flag = false
      this.incomeData.forEach((item) => {
        if (item.date === newVal) {
          // console.log('sss')
          console.log(item.income)
          this.inc = item.income.toFixed(2)
          flag = true
        }
      })
      if (!flag) {
        this.inc = '暂无数据'
      }
    },
  },
  methods: {
    pageChange(val) {
      this.query.page = val
      this.getCheckinRecord()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getCheckinRecord()
    },
    changeDate() {},
    async getIncomeList() {
      const { data: res } = await getIncome()
      //   console.log(res)
      if (res.code === 1) {
        let data = res.data
        for (let key in data) {
          this.incomeData.push({
            date: key,
            income: data[key],
          })
        }
      } else {
        this.$message.error(res.message)
      }
    },
    async getCheckinRecord() {
      const { data: res } = await currentCheckInRecord(this.query)
      //   console.log(res)
      if (res.code === 1) {
        this.checkinData = res.data
        this.exp.total = res.exp.num
      } else {
        this.$message.error(res.message)
      }
    },
  },
}
</script>

<style scoped></style>
